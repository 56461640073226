<template>
    <div>
        <label v-if="label">{{required ? label + '*' : label}}</label>
        <v-item-group 
            :value="multiple ? newValueArray : newValue" :multiple="multiple || false" 
            :class="errors && errors.length ? 'hasError' : ''"
             :disabled="disabled"
            @change="change">
            <v-row :justify="justify" align="stretch" style="margin: 0 -8px;" class="d-flex">
                <v-col :lg="itemCol || 3" :md="4" :sm="6" cols="12"
                    class="pa-2 text-center" ref="items"
                    v-for="(option, index) in options" :key="option.value">
                    <v-item v-slot="{ active, toggle }" :value="option.value" :disabled="disabled || option.disabled">
                        <v-card
                            :disabled="disabled"
                            class="align-stretch radio-item-card"
                            :color="active ? 'selected-item' : ''"
                            @click="toggle"
                            @keypress.enter="toggle"
                            style="position: relative;"
                            :class="{'horizontal': alignment == 'horizontal'}"
                            :style="{'height': height }"
                            >
                            <div class="radio-item-inner pa-4" 
                                :class="{'setHeight': height}"
                                :ref="'item' + index"
                                > 
                                <template v-if="option.icon">
                                    <div class="button-icon" :class="{'circle': circle }" :style="{'height': containerSize, 'width': containerSize,  'line-height': containerSize, 'font-size': iconSize }">
                                        <i :class="option.icon" ></i>
                                    </div>       
                                </template>
                                <span v-if="option.text" class="label-primary" v-html="option.text"></span>
                                <span v-if="option.subtext" class="label-secondary" v-html="option.subtext"></span>
                            </div>
                            

                            <div class="check-icon" v-if="showCheck && active">
                                <i class="fas fa-check-circle fa-lg"></i>
                            </div>

                            <v-chip small color="#00B3E3" class="white--text beta-chip" v-if="option.beta">
                                Beta
                            </v-chip>
                            <v-chip small color="#00B3E3" class="white--text beta-chip" v-if="option.comingsoon">
                                Coming Soon
                            </v-chip>                            
                        </v-card>
                    </v-item>
                </v-col>
            </v-row>
        </v-item-group>
        <ErrorMessages :errors="errors"></ErrorMessages>  
    </div>
</template>

<script>

export default {
    name: 'RadioItemGroup',
    props: {
        options: Array,
        rules: String,
        value: [Number, String, Boolean, Object],
        label: String,
        required: Boolean,
        iconSize: String,
        itemCol: Number,
        multiple: Boolean,
        justify: String,
        showCheck: Boolean,
        circle: Boolean,
        containerSize: String,
        height: String,
        alignment: String,
        errors: Array,
        disabled: Boolean,
    },
    emits: [
        'change'
    ],
    data() {
        return {
            newValue: this.multiple ? {} : null,
        }
    },
    watch: {
        newValue: {
            deep: true,
            handler(newVal) {
                if (newVal !== this.value) {
                    this.emitValue();
                }      
            } 
        },
        value: {
            immediate: true,
            deep: true,
            handler(newVal) {
                if (!this.multiple) {
                    this.newValue = newVal;
                } else if (!newVal) {
                    let newValue = {};
                    this.options.forEach(o => {
                        newValue[o.value] = false;
                    });
                    this.newValue = newValue;
                } else { 
                    this.newValue = newVal;
                }
                
            }
        }

    },
    computed: {
        newValueArray() {
            return Object.keys(this.newValue).filter(v => this.newValue[v] === true);
        }
    },
    methods: {
        emitValue() {
            this.$emit('change', this.newValue)       
        },
        toggle() {

        },
        change(v) {
            if (!this.multiple) {
                this.newValue = v;
            } else {
                let newValue = {};
                this.options.forEach(o => {
                    const value = o.value;
                    if (v.indexOf(value) > -1) {
                        newValue[value] = true;
                    } else {
                        newValue[value] = false;
                    }
                });
                this.newValue = newValue;
            }
            //this.emitValue();
        },
    },
    mounted() {

    }

}


</script>

<style scoped lang="scss">
    @import '@/style.scss';

    .radio-item-card {
        border: 2px solid $amwins-blue;
        position: relative;
        margin-bottom: 4px;
        height: 100%;
    }

    .radio-item-card * {
        color: $amwins-blue;
    }

    span {
        display: block;
    }
    .selected-item {
        background-color: $amwins-blue;
    }

    .selected-item .button-icon path,
    .selected-item span,
    .selected-item .button-icon {
        color: white!important;
        border-color: white!important;

        * {
            color: white!important;
        }
    }

    .button-icon {
        margin-bottom: 5px;
        display: inline-block;
        text-align: center;

        &.circle {
            border-radius: 100%;
            border: 2px solid $amwins-blue;
        }
    }

    .horizontal {
        text-align: left;
    }

    .horizontal .button-icon {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle;
    }

    .horizontal .label-primary,
    .horizontal .label-secondary {
        text-align: left;
        display: inline-block;
        vertical-align: middle;
    }

    .label-primary {
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
    }

    .label-secondary {
        font-size: 12px;
        line-height: 18px;
    }

    .v-card--link:before {
        display: none!important;
    }

    .check-icon {
        position: absolute;
        top: 4px;
        right: 4px;
        * {
            color: $tertiary-yellow!important;
        }
        
    }

    @media screen and (max-width: 960px) {
        .v-card {
            height: auto!important;
        }
    }

    @media screen and (min-width: 961px) {
        .radio-item-inner.setHeight {
            margin: auto;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            width: 100%;
        }
    }

    label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
    }

    .hasError .v-card {
        border-color: $tertiary-red!important;
    }

    .beta-chip {
        width: auto;
        display: inline-block;
        position: absolute;
        top: 4px;
        left: 4px;
    }


    
</style>
